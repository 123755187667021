import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerVacancy from 'components/flex/Banner/BannerVacancy'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import BannerThanks from 'components/flex/Banner/BannerThanks'

interface BannerShellProps {
  fields: any
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerShellProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    default: BannerDefault,
    vacancy: BannerVacancy,
    detail: BannerDetail,
    thanks: BannerThanks,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
