import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Elements
import FingerPrint from 'components/elements/FingerPrint'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerDefault = styled.section<{ image: boolean }>`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  position: relative;

  @media screen and (min-width: 992px) {
    height: 430px;
  }

  @media screen and (max-width: 991px) {
    ${({ image }) =>
      image
        ? css`
            height: 500px;
          `
        : css`
            height: 300px;
          `}
  }

  @media screen and (max-width: 575px) {
    ${({ image }) =>
      image
        ? css`
            height: 400px;
          `
        : css`
            height: 300px;
          `}
  }
`

const BannerBackground = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440px;

  @media (min-width: 1439px) {
    left: calc(50vw - 770px);
  }

  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 800px;
      top: -150px;
      left: 50%;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const BannerWrapper = styled.div``

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.regular};
    @media (min-width: 1199px) {
      line-height: ${(props) => props.theme.font.size.unit};
      font-size: ${(props) => props.theme.font.size.giga};
    }

    & span {
      @media (max-width: 991px) {
        font-size: 26pt !important;
      }
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.giga};
      font-size: ${(props) => props.theme.font.size.mega};
    }

    @media (max-width: 767px) {
      line-height: ${(props) => props.theme.font.size.mega};
    }
  }

  & p {
    @media (max-width: 991px){
      & br {
        display: none;
      }
      font-size: 13px;
      line-height: 25px;

      & span {
        line-height: 25px;
      }
    }
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  z-index: 1;

  @media (min-width: 992px) {
    width: 45%;
    height: 70%;
    max-width: 610px;
  }

  @media (max-width: 991px) {
    width: 550px;
    height: 60%;
  }
  @media (max-width: 767px) {
    width: 440px;
    height: 50%;
  }
  @media (max-width: 575px) {
    width: 350px;
    height: 40%;
  }

  & img {
    object-fit: contain !important;
    object-position: center bottom;
  }
`

const BannerRow = styled.div`
  @media screen and (min-width: 992px) {
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    padding-top: 40px;
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault
    image={!!fields.image}
    className="position-relative w-100"
  >
    <BannerWrapper className="container h-100">
      <BannerRow className="row">
        <div className="col-lg-7 pt-5 d-flex align-items-center h-100 ">
          <Content content={fields?.description} />
        </div>
      </BannerRow>
    </BannerWrapper>
    <BannerBackground>
      <FingerPrint />
      <Image image={fields?.image} alt="Banner afbeelding" />
    </BannerBackground>
  </StyledBannerDefault>
)

export default BannerDefault
