import React from 'react'

// Third Party
import styled from 'styled-components'

// Components
import FingerPrint from 'components/elements/FingerPrint'
import ParseContent from 'components/shared/ParseContent'
import ButtonTransparentArrow from 'components/elements/Buttons/ButtonTransparentArrow'
import Plaatjie from '@ubo/plaatjie'

interface BannerThanksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerThanks = styled.section`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  position: relative;
  height: fit-content;
`

const BannerBackground = styled.div`
  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 900px;
      top: -30px;
      left: 60%;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const BannerWrapper = styled.div`
  padding-top: 100px;
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.regular};
    @media (min-width: 992px) {
      line-height: ${(props) => props.theme.font.size.unit};
      font-size: ${(props) => props.theme.font.size.giga};
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.giga};
      font-size: ${(props) => props.theme.font.size.mega};
    }

    @media (max-width: 767px) {
      line-height: ${(props) => props.theme.font.size.mega};
    }
  }

  & p {
    font-size: 20px;
  }
`

const Step = styled.div`
  color: ${({ theme }) => theme.color.light};
`

const ImageWrapper = styled.div`
  border: 8px solid ${({ theme }) => theme.color.light};
  height: 130px;
  width: 130px;
  border-radius: 65px;
  position: relative;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  & img {
    width: 100%;
    height: 100%;
  }
`

const StepCounter = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  top: -20px;
  left: -20px;
  background-color: ${({ theme }) => theme.color.light};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 28px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const StyledButtonTransparentArrow = styled(ButtonTransparentArrow)`
  & a,
  button {
    color: ${({ theme }) => theme.color.light};
    &:hover {
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const BannerThanks: React.FC<BannerThanksProps> = ({ fields }) => {
  const [formType, setFormType] = React.useState<string | null>('')

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('form')

    setFormType(params)
  }, [])

  return (
    <StyledBannerThanks className="position-relative">
      <BannerWrapper className="container h-100">
        <div className="row">
          <div className="col-12 pt-5 d-flex align-items-center h-100 ">
            <Content content={fields?.description} />
          </div>
          <div className="col-12 pt-5">
            <div className="row pt-4">
              {fields.steps?.map((step, index) => (
                <div className="col-lg col-md-4 col-sm-6 mb-5">
                  <Step className="d-flex justify-content-center">
                    <div>
                      <ImageWrapper className="mb-4">
                        <StepCounter>{`${index + 1}.`}</StepCounter>
                        <Plaatjie image={step?.icon} alt="icon" />
                      </ImageWrapper>
                      <ParseContent content={step?.description} />
                    </div>
                  </Step>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 py-5 d-flex justify-content-center">
            <StyledButtonTransparentArrow to={fields.link?.url || '/'}>
              {fields.link?.title}
            </StyledButtonTransparentArrow>
          </div>
        </div>
      </BannerWrapper>
      <BannerBackground>
        <FingerPrint />
      </BannerBackground>
    </StyledBannerThanks>
  )
}

export default BannerThanks
