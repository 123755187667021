import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// UBO
import Plaatjie from '@ubo/plaatjie'

// Elements
import FingerPrint from 'components/elements/FingerPrint'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  position: relative;
  height: 430px;
  margin-bottom: -21%;

  @media (min-width: 1599px) {
    margin-bottom: -16%;
  }

  @media screen and (max-width: 991px) {
    height: 350px;
  }
`

const BannerBackground = styled.div`
  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 800px;
      top: -150px;
      left: 50%;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const BannerWrapper = styled.div``

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.light};
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.regular};
    @media (min-width: 992px) {
      line-height: 94px;
      font-size: 75px;
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.giga};
      font-size: ${(props) => props.theme.font.size.mega};
    }

    @media (max-width: 767px) {
      line-height: ${(props) => props.theme.font.size.mega};
    }
  }
`

const Image = styled(Plaatjie)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 992px) {
    width: 45%;
    max-width: 610px;
  }

  @media (max-width: 991px) {
    width: 550px;
  }
  @media (max-width: 767px) {
    width: 440px;
  }
  @media (max-width: 575px) {
    width: 350px;
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault className="position-relative">
    <BannerWrapper className="container h-100">
      <div className="row h-100">
        <div className="col-lg-7 pb-5 d-flex align-items-center h-100 ">
          <Content content={fields?.description} />
        </div>
      </div>
    </BannerWrapper>
    <BannerBackground>
      <FingerPrint />
      <Image image={fields?.image} alt="Banner afbeelding" />
    </BannerBackground>
  </StyledBannerDefault>
)

export default BannerDefault
