import React from 'react'

// Third Party
import styled from 'styled-components'

// Elements
import FingerPrint from 'components/elements/FingerPrint'

interface BannerDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerDetail = styled.section`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  position: relative;
  height: 430px;
`

const BannerBackground = styled.div`
  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 800px;
      top: -150px;
      left: 50%;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const BannerDetail: React.FC<BannerDetailProps> = () => (
  <StyledBannerDetail className="position-relative">
    <BannerBackground>
      <FingerPrint />
    </BannerBackground>
  </StyledBannerDetail>
)

export default BannerDetail
